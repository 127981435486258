




















import {Component, Vue} from 'vue-property-decorator';
import UTable, {TableField} from '@/ui-lib/table/Table.vue';
import UBlock from '@/ui-lib/block/Block.vue';
import {Preset} from '@/types';
import {PresetService} from '@/api';

@Component<PresetListView>({
  components: {
    UTable,
    UBlock,
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const list = await PresetService.list();

      next((vm) => {
        vm.list = list;
      });
    } catch (e) {
      next(e);
    }
  },

  metaInfo() {
    return {
      title: 'Пресеты',
    };
  },
})
export default class PresetListView extends Vue {
  private list: Preset[] = [];

  private get fields(): TableField[] {
    return [
      {
        name: 'id',
        label: 'ID',
      },

      {
        name: 'name',
        label: 'Название',
      },

      {
        name: 'combinationCount',
        label: 'Всего комбинаций',
      },
    ];
  }
}
